<template>
  <div class="practise-container">
    <div v-if="paper" class="paper-container">
      <div class="core">
        <div class="top">
          <div class="title">{{ paper.nodeName }}</div>
        </div>
        <div class="content">
          <div class="item" v-for="(topic, index) in topics" :key="index">
            <template v-if="topic.nodeType === 'bigQuestion'">
              <span v-if="topic.shortName">{{ topic.shortName }}、</span>
              <span>{{ topic.nodeName }}</span>
              <span v-if="topic.itemExplain">({{ topic.itemExplain }})</span>
            </template>
            <template v-else-if="topic.nodeType === 'groupQuestion' || topic.nodeType === 'childGroupQuestion'">
              <span v-if="topic.trunkNumber" class="number">{{ topic.trunkNumber }}</span>
              <div class="trunk" :class="{ level2: topic.topicLevel === 2, level3: topic.topicLevel === 3 }">
                <div :class="{ 'choice-trunk': topic.questionType === 'choice', 'blank-trunk': topic.questionType === 'blank'}" v-html="topic.trunk" />
              </div>
            </template>
            <template v-else-if="topic.nodeType === 'question' || topic.nodeType === 'childQuestion'">
              <span v-if="topic.parentNumber" class="number">{{ topic.parentNumber }}</span>
              <span v-if="topic.trunkNumber" class="number">{{ topic.trunkNumber }}</span>
              <div class="trunk" :class="{ level2: topic.topicLevel === 2, level3: topic.topicLevel === 3 }">
                <div :class="{ 'choice-trunk': topic.questionType === 'choice', 'blank-trunk': topic.questionType === 'blank'}" v-html="topic.trunk" />
                <div v-if="topic.choices && topic.choices.length" class="choice">
                  <span v-for="(choice, ci) in topic.choices" :key="ci">
                    <span class="real">
                      <span style="margin-right: 10px;">{{ choice.letter }}.</span>
                      <span v-html="choice.option" />
                    </span>
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- <template v-else>practise</template> -->

    <transition name="fade">
      <div v-if="loading" class="loading">
        正在加载...
      </div>
    </transition>
  </div>
</template>

<script>
import request from "../../request";

export default {
  name: "Practise",
  data() {
    return {
      id: null,
      token: null,
      paper: null,
      topics: null,
      answers: null,
      loading: true
    }
  },
  created() {
    document.title = '个性化练习'
    this.getPaper()
    this.loadMathJax()
  },
  methods: {
    loadMathJax() {
      window.MathJax = {
        startup: {
          pageReady: () => {
            return window.MathJax.startup.defaultPageReady().then(() => {
              this.$nextTick(() => {
                if (window.MathJax) {
                  window.MathJax.typeset()
                }
              })
            })
          }
        },
        tex: {
          inlineMath: [['$', '$'], ['\\(', '\\)'], ['\\[', '\\]']],
          displayMath: [['$$', '$$']]
        }
      }
      this.loadScript()
    },
    loadScript() {
      const script = document.createElement('script')
      const head = document.getElementsByTagName('head')[0]
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://pen.zwres.com/static-assets/mathJax/es5/tex-svg.js'
      head.appendChild(script)
    },
    setParams() {
      const params = this.$route.query
      this.id = params.id
      this.token = params.token
    },
    getPaper() {
      this.setParams()
      request({
        url: '/api/paper/v2/' + this.id,
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      }).then(response => {
        this.loading = false
        this.paper = response.data
        this.setTopic(response.data)
        console.log('setTopic', this.topics)
        this.math()
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    math() {
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset()
        }
      })
    },
    setTopic(paper) {
      const loop = (child) => {
        child.children.forEach(node => {
          const type = node.nodeType
          const firstQuestion = child.nodeType === 'bigQuestion' && type !== 'bigQuestion'
          if (firstQuestion) {
            this.trimQuestion(node)
          } else if (type === 'bigQuestion') {
            node.topicId = node.nodeId || node.id
            const big = this.trimBig(node)
            this.topics.push(big)
            // 插入答案
            this.answers.push({
              id: node.id,
              name: node.nodeName,
              nodeType: node.nodeType,
              shortName: node.shortName
            })
            loop(node)
          }
        })
      }
      this.topics = []
      this.answers = []
      loop(paper)
    },
    trimQuestion(node) {
      // 设置主题干的序号（简称）
      node.content.trunkNumber = node.sort + '.'
      // 是否需要答题区标记
      let answerSpace = false
      // 如果题干为空，则把topicLevel等级设成0，方便子题计算
      if (node.nodeName === '') {
        node.topicLevel = 0
      }
      const loop = (child, index, parent) => {
        if (child.nodeType === 'smallQuestion') {
          return false
        }
        // 如果题干为空将不写入列表进行渲染
        if (child.nodeName !== '') {
          const level = parent ? parent.topicLevel + 1 : 1
          const topic = {
            id: child.id,
            itemId: child.topicId,
            nodeId: child.nodeId,
            trunk: child.nodeName,
            trunkNumber: child.content.trunkNumber,
            nodeType: child.nodeType,
            point: child.point,
            questionType: child.questionType,
            sort: child.sort,
            topicId: child.topicId,
            topicLevel: level, // 题目的层级
            typeId: child.typeId, // 题目类型ID
            choices: child.content.choices,
            topId: node.topicId, // 主题干的ID
          }
          // 如果本道题是解答题，且不是题组
          // 如果本道题是解答题且是题组，且题组下有某道子题是解答题类型
          // 以上两种情况需要加上答题区
          const noChild = node.questionType === 'other' && (!node.children || !node.children.length)
          const hasChild = parent && child.questionType === 'other'
          if (noChild || hasChild) {
            answerSpace = true
          }
          // 如果是叶子节点才允许切片
          if (child.nodeType === 'question' || child.nodeType === 'childQuestion') {
            topic.cut = true
          }
          // 如果父级题干为空，则第1个子题将继承父题干的序号
          if (parent && parent.nodeName === '' && !index) {
            topic.parentNumber = parent.content.trunkNumber
          }
          // 插入答案
          this.answers.push({
            id: child.id,
            answer: child.content.answer,
            questionType: child.questionType,
            nodeType: child.nodeType,
            typeId: node.typeId,
            level: level,
            trunkNumber: topic.trunkNumber,
            answerType: child.content.answerType
          })
          // 加入列表
          this.topics.push(topic)
        }
        if (child.children && child.children.length) {
          child.children.forEach((item, i) => {
            loop(item, i, child)
          })
        }
      }
      loop(node, null)
      // 如果本题需要答题区，则设置题目最后一个节点标记，方便在该节点下插入答题区
      if (answerSpace) {
        this.topics[this.topics.length - 1].last = true
      }
    },
    trimBig(node) {
      return {
        id: node.id,
        itemId: node.nodeId || node.id,
        itemExplain: node.itemExplain,
        nodeId: node.nodeId,
        nodeName: node.nodeName,
        nodeType: node.nodeType,
        point: node.point,
        shortName: node.shortName,
        sort: node.sort
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$fontSize: 10.5pt;
$fontFamily: FZSSK,serif;
$lineHeight: 1.6;
.practise-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .paper-container {
    // width: 210mm;
    // padding: 2cm;
    width: 100%;
    padding: 20px;
    box-sizing:border-box;


    .core {
      .top {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
      .content {
        .item {
          display: flex;
          line-height: $lineHeight;
          .number {
            font-size: $fontSize;
            line-height: $lineHeight;
            padding-right: 2px;
            font-family: $fontFamily;
          }
          .image-space {
            position: relative;
          }
          .trunk {
            font-family: $fontFamily;
            width: 100%;
            .choice-trunk {
              word-break: break-word;
              span {
                display: inline-block;
              }
            }
            >div {
              font-family: $fontFamily;
              font-size: $fontSize;
              line-height: $lineHeight;
            }
            ::v-deep p {
              font-family: $fontFamily;
              font-size: $fontSize;
              line-height: $lineHeight;
              margin: 0;
            }
            ::v-deep .choice {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              font-family: $fontFamily;
              font-size: $fontSize;
              line-height: $lineHeight;
              .real {
                p {
                  display: inline-block;
                  font-family: $fontFamily;
                  font-size: $fontSize;
                  line-height: $lineHeight;
                }
              }
            }
            .row {
              flex-direction: row;
            }
          }
          ::v-deep .trunk {
            * {
              font-family: $fontFamily;
              //font-size: $fontSize;
            }
            sup {
              font-size: 6pt;
            }
            > div {
              word-break: break-word;
              font-size: $fontSize;
              vertical-align: baseline;
            }

            mjx-container[jax="SVG"] > svg {
              shape-rendering: crispEdges;
            }
            .choice-trunk {
              word-break: break-word;
              span {
                display: inline-block;
              }
            }
            .blank-trunk {
              word-break: break-all;
              span {
                //white-space: break-spaces;
                //display: inline-block;
                //word-break:normal;
                //white-space:pre-wrap;
              }
            }
          }
          .level2 {
            padding-left: 20px;
          }
          .level3 {
            padding-left: 40px;
          }
          ::v-deep table {
            border: 1px solid;
            border-collapse: collapse;
            td{
              border: 1px solid;
              font-size: $fontSize;
              line-height: $lineHeight;
            }
          }
        }
      }
    }
  }
}
</style>
